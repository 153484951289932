import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contribute"
    }}>{`Contribute`}</h1>
    <hr></hr>
    <p>{`Found a bug? Is some of the documentation outdated? The source code for this site is available on `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice"
      }}>{`GitHub`}</a>{` for anyone to contribute to. See our `}<a parentName="p" {...{
        "href": "https://github.com/City-of-Helsinki/bestpractice/blob/master/.github/contributing.md"
      }}>{`practices on accepting contributions`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      